* {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.row {
    display: flex;
}

.row-b {
    display: flex;
    justify-content: space-between;
}

.row-a {
    display: flex;
    justify-content: space-around;
}

.row-e {
    display: flex;
    justify-content: space-evenly;
}

.main-body {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: none;
}

.top-bar {
    height: 60px;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    overflow: hidden;
    z-index: 7;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.body-1 {
    background-image: linear-gradient(to right top, #030129, #8BBDFE);
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    overflow-x: none;
}

.logo {
    font-size: 32px;
    color: #03003b;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 6px;
}

.top-item {
    text-decoration: none;
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 600;
    color: #6b6a85;
    padding-right: 40px;
    padding-top: 14px;
}

.top-join {
    width: auto;
    height: 44px;
    font-size: 20px;
    font-weight: 600;
    color: #ebebeb;
    background-color: #19147ff1;
    border: none;
    border-radius: 5px;
    margin-top: 6px;
    margin-right: 20px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.half {
    width: 50vw;
    height: calc(100vh - 100px);
    margin-top: 100px;
}

.head {
    color: white;
    font-size: 60px;
    max-width: 380px;
    padding-left: 8vw;
}

.sub-head {
    color: white;
    font-size: 20px;
    max-width: 560px;
    padding-left: 8vw;
}

.space-30 {
    width: 30px;
}

.body-1-btn {
    aspect-ratio: 4.287;
    min-width: 240px;
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.body-2-btn {
    aspect-ratio: 4.287;
    min-width: 240px;
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    background-color: white;
}

.joi {
    background-color: rgb(255, 255, 255);
    padding-left: 9px;
    padding-right: 9px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.exp {
    background-color: #03003b;
    color: white;
}

.space-40 {
    width: 40px;
}

.space-20vw {
    width: 8vw;
}

.padd-l {
    width: 50vw;
}

.heit-60 {
    height: 60px;
}

.tree {
    mix-blend-mode: multiply;
    background-repeat: no-repeat;
    padding-top: 14vh;
    height: 40vw;
    width: 40vw;
}

.body-2 {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    overflow-x: none;
    overflow-y: auto;
}

.about-head {
    color: rgb(0, 22, 92);
    font-size: 60px;
    max-width: 50vw;
    font-weight: 700;
    padding-left: 8vw;
}

.about-sub-line {
    width: 45px;
    height: 2px;
    background-color: #636363;
    margin-left: 8vw;
    margin-top: 10px;
}

.about-sub-head {
    color: rgb(96, 96, 96);
    font-size: 22px;
    max-width: 62vw;
    font-weight: 700;
    padding-left: 24px;
}

.height-60 {
    height: 60px;
    width: 100%;
}

.height-20 {
    height: 60px;
}

.body-3 {
    min-height: 100vh;
    width: 100vw;
}

.a-head {
    font-size: 30px;
    font-weight: 700;
    background: linear-gradient( to right, #f32170, #ff6b08, #cf23cf, #eedd44);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

video {
    width: 66vw;
    height: 37vw;
    background-color: black;
    border-radius: 12px;
    border: 1px solid grey;
    fill-opacity: inherit;
}

.imgg {
    height: 20vw;
    width: 40vw;
}

.imtt {
    height: 10vw;
    width: 20vw;
}

.qq {
    width: 20vw;
}

.body-4 {
    min-height: 600px;
    width: 100vw;
    padding-bottom: 60px;
    padding-top: 60px;
}

.about-head-1 {
    color: rgb(0, 22, 92);
    font-size: 60px;
    max-width: 50vw;
    font-weight: 700;
}

.about-sub-head-1 {
    color: rgb(96, 96, 96);
    font-size: 22px;
    max-width: 40vw;
    font-weight: 700;
}

.top-join-1 {
    width: auto;
    height: 44px;
    font-size: 20px;
    font-weight: 600;
    color: #ebebeb;
    background-color: #19147ff1;
    border: none;
    border-radius: 5px;
    margin-top: 6px;
    margin-right: 20px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.hands {
    mix-blend-mode: multiply;
    background-repeat: no-repeat;
    width: 30vw;
    height: 30vw;
}

.space-20 {
    width: 40px;
}

.height-10 {
    height: 10px;
}

.fotter {
    width: 100vw;
    height: 40px;
    background-color: #444358e9;
}

.fotter-text {
    padding-top: 8px;
    color: white;
    font-size: 14px;
}

.bt-g {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 256px));
    row-gap: 20px;
    column-gap: 30px;
}

@media (max-width:900px) {
    #mobilebar {
        display: none;
    }
    .tree {
        display: none;
    }
}