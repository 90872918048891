    .boddy {
        width: 100vw;
        height: calc(100vh -80px);
        margin-top: 80px;
    }
    
    .c-box {
        max-width: 1000px;
        width: 100%;
        height: auto;
        min-height: 100vh;
    }
    
    .c-head {
        font-size: 30px;
        color: rgb(5, 5, 107);
        font-weight: 800;
        padding-bottom: 24px;
    }
    
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 480px));
        row-gap: 10px;
        column-gap: 10px;
        padding: 10px;
    }
    
    .img-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 160px));
        row-gap: 10px;
        column-gap: 10px;
        padding: 10px;
    }
    
    .img-grid img {
        aspect-ratio: 2/1;
        width: 100%;
    }
    
    @media (min-width:490px) {
        .g-item {
            width: 100%;
            aspect-ratio: 3/0.9;
            background-color: white;
            border-radius: 6px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }
    
    @media (max-width:490px) {
        .g-item {
            width: 100%;
            aspect-ratio: 3/1.2;
            background-color: white;
            border-radius: 6px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }
    
    .hed {
        font-size: 24px;
        font-weight: 600;
        color: rgb(0, 39, 78);
        padding-left: 4px;
        padding-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        -webkit-line-clamp: 1;
        line-height: 30px;
        max-height: 30px;
    }
    
    .sed {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 15px;
        max-height: 28px;
        padding: 4px;
    }
    
    .grid-e {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px, 460px));
        row-gap: 10px;
        column-gap: 10px;
        padding: 10px;
    }
    
    .enroll-n {
        text-decoration: none;
        color: white;
        background-color: rgb(16, 16, 118);
        border-radius: 5px;
        height: 34px;
        text-align: center;
        padding-top: 6px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }